import * as React from "react";
import { Page } from "../components/templates/page/page";
import { AboutUsSectionGreenHydrogen } from "../sections/about-us/about-us-green-hydrogen.section";
import { AboutUsHero } from "../sections/about-us/about-us-hero";
import {
  AboutSectionQuoteCraig,
  AboutUsSectionQuoteRobert,
  AboutUsSectionUniqueDesign,
  AboutUsSectionWorldClassTechnology,
} from "../sections/about-us/about-us-info.section";
import { AboutUsSectionIntro } from "../sections/about-us/about-us-intro.section";
import { AboutUsSectionPartners } from "../sections/about-us/about-us-partners.section";

const AboutUsPage = () => (
  <Page title={"About us"}>
    <AboutUsHero />
    <AboutUsSectionIntro />
    <AboutSectionQuoteCraig />
    <AboutUsSectionWorldClassTechnology />
    <AboutUsSectionPartners />
    <AboutUsSectionGreenHydrogen />
    <AboutUsSectionQuoteRobert />
    <AboutUsSectionUniqueDesign />
  </Page>
);

export default AboutUsPage;
