import * as React from "react";
import { Paragraph, Paragraphs } from "../../components/atoms/typography";
import { Section } from "../../components/templates/page/section/section";
import { SectionIntroHeading, SectionIntroLayout } from "../../components/templates/page/section/section-intro";

export const AboutUsSectionIntro = () => (
  <Section>
    <SectionIntroLayout
      left={<SectionIntroHeading maxWidth={500}>Clean Hydrogen Implementations</SectionIntroHeading>}
      right={
        <Paragraphs>
          <Paragraph maxWidth={450}>
            Intimate knowledge of hydrogen equipment, Proven and validated expertise in hydrogen safety, Proven and
            validated expertise in Australian and global hydrogen regulatory compliance, Industry-leading experience in
            designing and building hydrogen refuelling installations, Leading expertise in hydrogen operations,
            installation support and maintenance management, Continuous improvement through laboratory-based research
            and development.
          </Paragraph>

          <Paragraph maxWidth={450}>
            Technical ability to deliver clean hydrogen skills development and on-site training, Long-term analysis of
            renewable transport asset acquisition, asset utilisation, and cost of ownership, and Mobile hydrogen
            refuelling and hydrogen storage expertise.
          </Paragraph>
        </Paragraphs>
      }
    />
  </Section>
);
