import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { SectionHero, SectionHeroImageCss } from "../../components/templates/page/section/section-hero";

export const AboutUsHero = () => (
  <SectionHero
    title={"Turnkey, end-to-end hydrogen project expertise and delivery"}
    image={
      <StaticImage
        src={`../../assets/images/nature/slate-water.jpg`}
        style={SectionHeroImageCss}
        placeholder="blurred"
        alt=""
      />
    }
  />
);
