import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { VerticalSpacer } from "../../components/gls/spacer";
import { InfoPanel } from "../../components/templates/page/panel/info-panel";
import { Section } from "../../components/templates/page/section/section";

export const AboutUsSectionGreenHydrogen = () => (
  <Section>
    <StaticImage
      src={`../../assets/images/infrastructure/curved-assembling-equipment.png`}
      placeholder="blurred"
      alt=""
    />
    <VerticalSpacer space={"xl"} />
    <InfoPanel
      title={"Generating renewable hydrogen"}
      description={[
        "H2FA’s 5-hectare site will initially be capable of producing 60-90kgs of green hydrogen from its own 750kw solar array. Ultimately the site will be scaled up to supply approximately 3,000kg with capacity to fuel 100+ vehicles per day.",
        "Due to the modular nature of H2FA’s hydrogen power facilities, the site will have equipment above ground, meaning that the operation can be re-located with minimal environmental impact and cost – a major advantage over legacy petroleum and diesel refuelling stations.",
      ]}
      points={["Circular power generation", "Island mode", "Minimal environmental impact"]}
    />
  </Section>
);
