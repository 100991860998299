import * as React from "react";
import {
  PartnerLogoGreenHydrogen,
  PartnerLogoIndro,
  PartnerLogoUMOE,
  PartnersHorizontal
} from "../../components/templates/common/partner-logo";
import { Section } from "../../components/templates/page/section/section";
import { Box } from "../../components/atoms/box";

export const AboutUsSectionPartners = () => (
  <Section backgroundColor={"ghostgumgrey300"} py={"md"}>
    <PartnersHorizontal>
      <PartnerLogoGreenHydrogen />
      <PartnerLogoIndro />
      <Box ml={{ sm: 40, md: 40, lg: 40 }}>
        <PartnerLogoUMOE />
      </Box>
    </PartnersHorizontal>
  </Section>
);
